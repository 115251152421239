<template>
    The DBhubs website is currently under maintenance. 
    Please check back in a few minutes or visit the 
    <a
        href="https://discord.gg/dbhubsrl" style="color:darkred">DBHubs Discord</a> server for more info.

    <!-- <button @click.prevent="call()">test</button> -->
    <!-- <button @click.prevent="call()">500</button>
  <button @click.prevent="call2()">400</button> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { API_URL } from '@/data/consts';

export default defineComponent({
    name: 'Placeholder',
    // setup() {
    //     const call = async () => {
    //         await fetch(`${API_URL}/`)
    //     }

    //     const call2 = async () => {
    //         await fetch(`${API_URL}/invoke-error-400`)
    //     }

    //     return {
    //         call,
    //         call2
    //     }
    // }
})
</script>

<style>
@import url(style/vars.css);

#app {
    font-family: Righteous;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    cursor: default;
    caret-color: transparent;
    -webkit-user-drag: none;
}

body {
    padding: 10vh;
    margin: 0;
}

img {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
</style>
